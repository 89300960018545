import { init } from '@amplitude/analytics-browser'
import { setUser } from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { BrandData, fetchBrandDataApi } from '../data/brand-data'
import { AMPLITUDE_ID, CLARITY_ID, analytics } from '../data/firebase-config'
import '../styles/globals.css'

export interface AppState {
  searchTerm?: string
  brandData: BrandData[]
  sizeFitler?: string
}

export interface AppStateManager {
  appState: AppState
  setAppState: Dispatch<SetStateAction<AppState>>
}

const defaultTrackingOptions = {
  attribution: true,
  pageViews: false,
  sessions: true,
  fileDownloads: true,
  formInteractions: true,
}

function MyApp({ Component, pageProps, router }: AppProps) {
  const [appState, setAppState] = useState<AppState>({
    brandData: [],
  })

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      console.log('beforeinstallprompt fired')
    })

    const userId = localStorage.getItem('userId')
    const userState = localStorage.getItem('useState')
    analytics
    if (userId && userState != 'anonymous') {
      init(AMPLITUDE_ID, userId, {
        defaultTracking: defaultTrackingOptions,
      })
    } else {
      init(AMPLITUDE_ID, { defaultTracking: defaultTrackingOptions })
    }
    setUser({ id: userId || undefined })
  }, [])

  const fetchBrandData = async () => {
    const brandData = await fetchBrandDataApi()
    setAppState((prevState) => ({
      ...prevState,
      brandData: brandData,
    }))
  }

  useEffect(() => {
    fetchBrandData()
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
          key={'viewport'}
        />
      </Head>
      <Script id="microsoft-clarity">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${CLARITY_ID}");
        `}
      </Script>
      <Script id="fb-pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '586927046922040');
        fbq('track', 'PageView');
        `}
      </Script>
      <Component appStateManager={{ appState, setAppState }} {...pageProps} />
    </>
  )
}

export default MyApp
