import { ServerResponseDto } from '../helpers/server-response-dto'
import { BASE_URL } from './config'

export type BrandData = {
  brandId: string
  name: string
  deliveryTerms: string[]
  returnTerms: string[]
  rating: number
  category: string
  image: string
  styles: string[]
}

export async function fetchBrandDataApi(): Promise<BrandData[]> {
  const response = await fetch(BASE_URL + '/static/brands', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const responseData: ServerResponseDto<{ brandData: BrandData[] }> =
    await response.json()

  return responseData.data.brandData
}
